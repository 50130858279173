<template>
    <div>
        <!-- Mobile menu -->
        <div
            class="mobile-menu fixed flex flex-col md:tailwind-hidden z-50 inset-0 bg-red overflow-y-auto translate-x-0 transform duration-700 ease-in-out"
            :class="{ '-translate-x-full ': !mobileNavOpen }"
        >
            <button class="absolute top-0 right-0 w-18 h-18 flex items-center justify-center text-white" @click.prevent="closeMobileNav" ref="mobileCloseButton">
                <svg height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6">
                    <g fill-rule="evenodd">
                        <path d="m0 0h9v3h-9z" transform="matrix(.70710678 .70710678 .70710678 -.70710678 .33273766 2.95405834)" />
                        <path d="m11 0h19v3h-19z" transform="matrix(.70710678 .70710678 .70710678 -.70710678 .33273766 2.95405834)" />
                        <g transform="matrix(.70710678 -.70710678 -.70710678 -.70710678 2.45405834 24.16726234)">
                            <path d="m0 0h9v3h-9z" />
                            <path d="m11 0h19v3h-19z" />
                        </g>
                    </g>
                </svg>
            </button>
            <transition v-on:before-enter="mobileBeforeEnter" v-on:enter="mobileEnter" v-on:leave="mobileLeave" v-bind:css="false">
                <div class="px-6 mt-24" v-if="mobileNavOpen">
                    <ul class="relative w-full space-y-5" ref="mobileNavItems">
                        <li v-for="(item, index) in navItems" :key="index" class="text-right">
                            <a
                                :href="item.url"
                                @click.prevent="toggleMobileSubnav(index)"
                                class="flex justify-end items-center font-serif font-semibold text-30 lg:text-56 text-gray-darker leading-none"
                                v-if="item.subitems.length"
                                ><span>{{ item.label }}</span
                                ><svg viewBox="0 0 14 6" class="ml-1 -mb-1 w-6 transform rotate-90"><path d="m9.81 5.12 1.63-1.63h-10.78v-1h10.76l-1.61-1.61.7-.71 2.83 2.83-2.83 2.83z" /></svg
                            ></a>
                            <a :href="item.url" class="flex justify-end items-center font-serif font-semibold text-30 lg:text-56 text-gray-darker leading-none" v-else
                                ><span>{{ item.label }}</span
                                ><svg viewBox="0 0 14 6" class="ml-1 -mb-1 w-6 transform rotate-90 opacity-0">
                                    <path d="m9.81 5.12 1.63-1.63h-10.78v-1h10.76l-1.61-1.61.7-.71 2.83 2.83-2.83 2.83z" /></svg
                            ></a>
                            <ul class="mr-8 mt-6 space-y-5" v-if="activeMobileIndex == index">
                                <li v-for="(subitem, index) in item.subitems" :key="index">
                                    <a
                                        :href="subitem.url"
                                        class="font-sans-bc md:font-serif md:font-bold text-20 md:text-26 text-white leading-none uppercase md:normal-case"
                                        :class="{ underline: subitem.active }"
                                        >{{ subitem.label }}</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </transition>
            <div class="relative w-full flex-1 bg-white p-6 mt-12 text-center">
                <div ref="mobileFooter">
                    <slot></slot>
                </div>
            </div>
        </div>

        <!-- Top nav/header -->
        <div
            class="fixed tailwind-hidden md:block z-30 top-0 left-0 w-full h-20 bg-white bg-opacity-90 backdrop-blur md:pl-100px transform transition duration-500 ease-out"
            :class="{ '-translate-y-full': scrollingUp }"
        >
            <div class="flex items-center justify-end mx-auto w-full h-full max-w-screen-xl px-6">
                <a href="#maincontent" class="sr-only focus:not-sr-only" style="margin-right: auto;">Skip to main content</a>
                <slot></slot>
            </div>
        </div>

        <!-- Vertical Nav Bar -->
        <div
            class="flex items-center md:items-start justify-between md:justify-start md:block md:fixed z-40 top-0 left-0 md:w-100px md:h-screen md:overflow-y-auto md:overflow-x-hidden bg-white md:shadow-nav pl-6 pr-4 md:px-0 py-2 md:py-5"
        >
            <a :href="siteUrl" class="block md:w-100px md:mb-12">
                <svg viewBox="0 0 81 127" xmlns="http://www.w3.org/2000/svg" class="w-10 md:w-18 md:mx-auto">
                    <g fill="none">
                        <g fill="#6d6e71">
                            <path d="m26.446875 0h54.459375v22.290246h-54.459375z" />
                            <path d="m.091875 22.2920839-.001875-22.2920839h24.894375v22.290246z" />
                            <path
                                d="m12.789375 109.701534c-.5775-2.205499-2.41125-3.969899-5.4-3.968061-2.7 0-4.3125 1.260811-4.33875 3.528799-.061875 4.978915 13.05 3.716266 13.051875 11.027496 0 3.308249-1.895625 6.678987-7.8075 6.680825-5.079375 0-7.0725-3.212677-8.295-5.921765l2.345625-.819711c.9375 2.389291 2.668125 4.59479 6.076875 4.59479s5.109375-1.766237 5.109375-4.096715c0-5.796787-13.05-4.093039-13.051875-11.277452 0-3.277005 2.795625-5.861115 6.975-5.861115 3.6 0 6.4275 1.856295 7.65 5.605644z"
                            />
                            <path
                                d="m32.206875 123.151404c-1.093125 1.858133-2.63625 3.811838-6.4275 3.813676-3.7275 0-7.265625-2.519783-7.265625-8.505876 0-5.293198 2.698125-8.505875 6.811875-8.507713 3.470625 0 6.365625 2.205499 6.365625 7.43437v1.512605h-10.86375c0 3.591288 2.18625 6.111071 4.951875 6.111071 2.0625 0 3.66375-1.073343 4.6275-2.773415zm-2.829375-6.206643c0-3.02521-1.48125-5.041404-3.988125-5.039567-2.795625 0-4.3125 2.021709-4.5 5.041405h8.484375z"
                            />
                            <path d="m38.116875 103.899233v22.747887h-2.304375v-22.747887h2.315625z" />
                            <path
                                d="m55.605 123.140376c-1.09125 1.859971-2.63625 3.811838-6.4275 3.813676-3.7275 0-7.265625-2.519783-7.265625-8.505875 0-5.293199 2.698125-8.507714 6.811875-8.507714 3.470625 0 6.365625 2.205499 6.365625 7.434371v1.512604h-10.86375c0 3.591288 2.18625 6.112909 4.951875 6.111071 2.0625 0 3.66375-1.073343 4.6275-2.775253zm-2.829375-6.206642c0-3.02521-1.48125-5.041404-3.988125-5.039567-2.795625 0-4.3125 2.021709-4.5 5.041405h8.484375z"
                            />
                            <path
                                d="m67.978125 115.139928c-.643125-1.98495-1.96125-3.243922-3.954375-3.243922-2.86125 0-4.53 2.389291-4.53 6.554008 0 4.164718 1.6725 6.552171 4.531875 6.552171 2.1225 0 3.31125-.694732 4.595625-3.027048l1.865625.819711c-1.670625 2.773415-3.15 4.161042-6.459375 4.161042-4.179375 0-6.849375-3.212677-6.849375-8.505876 0-5.293198 2.66625-8.507713 6.845625-8.507713 3.43875 0 5.04375 1.889378 6.106875 4.440405z"
                            />
                            <path
                                d="m74.469375 110.254747v-5.386932h2.31375v5.38877h3.855v1.953704h-3.853125v11.657902c0 .755384.545625 1.133994 1.8975 1.133994.6906967.001224 1.3803896-.051631 2.0625-.158061v1.858134c-.41625.124978-1.35.253632-2.7.253632-2.506875 0-3.5625-.630405-3.5625-2.709088v-12.03835h-2.745v-1.951867h2.73z"
                            />
                        </g>
                        <path
                            d="m.09 23.6980897v36.4532272c1.28095403-1.579428 2.84614286-2.9155744 4.618125-3.9423299 3.18875-1.8379161 6.545625-2.7568741 10.070625-2.7568741 2.6334251.0097974 5.2330661.5817839 7.618125 1.6761794 2.4156775 1.0631722 4.5304927 2.6876228 6.16125 4.7326339 1.125 1.4237723 2.025625 3.3088615 2.701875 5.6552677l-8.285625 2.3029088c-.59375-2.3843897-1.649375-4.187998-3.166875-5.4108248-1.5175-1.2228269-3.338125-1.8354656-5.461875-1.8379161-3.17625 0-5.6475 1.4911626-7.41375 4.4734877s-2.651875 6.8811577-2.656875 11.6964978c0 3.0423637.405 5.7085673 1.215 7.9986107s1.93 3.9790883 3.36 5.0671346c1.4275 1.0892716 2.973125 1.6332948 4.636875 1.6320695 2.5 0 4.729375-.8760733 6.688125-2.62822 1.38625-1.2154751 2.479375-2.9694597 3.279375-5.2619537l8.22 2.2918814c-.635 2.4909889-1.438125 4.4698118-2.409375 5.9364688-1.5180839 2.332042-3.6079351 4.2541861-6.07875 5.5909407-.8013733.4321986-1.6402875.7938481-2.506875 1.0806947h18.028125l.016875-44.1559335h8.89125l-.016875 44.1522576h33.40125v-74.7462084z"
                            fill="#ee3524"
                        />
                    </g>
                </svg>
            </a>
            <button class="md:tailwind-hidden w-12 h-12 flex items-center justify-center text-red" @click.prevent="openMobileNav">
                <svg height="25" viewBox="0 0 30 25" width="30" xmlns="http://www.w3.org/2000/svg" class="fill-current w-8 h-8">
                    <g fill-rule="evenodd">
                        <path d="m0 0h9v3h-9z" transform="matrix(1 0 0 -1 0 3)" />
                        <path d="m11 0h19v3h-19z" transform="matrix(1 0 0 -1 0 3)" />
                        <path d="m0 0h9v3h-9z" transform="matrix(1 0 0 -1 0 25)" />
                        <path d="m11 0h19v3h-19z" transform="matrix(1 0 0 -1 0 25)" />
                        <path d="m0 0h9v3h-9z" transform="matrix(1 0 0 -1 21 14)" />
                        <path d="m11 0h19v3h-19z" transform="matrix(1 0 0 -1 -11 14)" />
                    </g>
                </svg>
            </button>
            <ul class="absolute vertical-nav tailwind-hidden md:flex items-center space-x-12 ml-10 pl-12">
                <li v-for="(item, index) in navItems" :key="index">
                    <a
                        :href="item.url"
                        @click.prevent="openSubnav(index)"
                        class="block mt-1 font-sans-ec text-18 leading-none uppercase whitespace-no-wrap transition duration-200 ease-in-out hover:text-red"
                        :class="{ 'text-gray': !item.active, 'text-red': item.active }"
                        v-if="item.subitems.length"
                        >{{ item.label }}</a
                    >
                    <a
                        :href="item.url"
                        class="block mt-1 font-sans-ec text-18 leading-none uppercase whitespace-no-wrap transition duration-200 ease-in-out hover:text-red"
                        :class="{ 'text-gray': !item.active, 'text-red': item.active }"
                        v-else
                        >{{ item.label }}</a
                    >
                    <ul class="sr-only">
                        <li v-for="(subitem, index) in item.subitems" :key="index">
                            <a :href="subitem.url">{{ subitem.label }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <!-- Overlay Menu -->
        <div class="overlay-menu fixed md:block z-50 inset-0" :class="{ open: subnavOpen }">
            <button class="absolute top-0 left-0 w-100px h-100px flex items-center justify-center text-white" @click.prevent="closeSubnav" style="z-index: 3;" ref="closeButton">
                <svg height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6">
                    <g fill-rule="evenodd">
                        <path d="m0 0h9v3h-9z" transform="matrix(.70710678 .70710678 .70710678 -.70710678 .33273766 2.95405834)" />
                        <path d="m11 0h19v3h-19z" transform="matrix(.70710678 .70710678 .70710678 -.70710678 .33273766 2.95405834)" />
                        <g transform="matrix(.70710678 -.70710678 -.70710678 -.70710678 2.45405834 24.16726234)">
                            <path d="m0 0h9v3h-9z" />
                            <path d="m11 0h19v3h-19z" />
                        </g>
                    </g>
                </svg>
            </button>
            <transition v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:leave="leave" v-bind:css="false">
                <div class="relative pl-100px mt-24" style="z-index: 4;" v-if="subnavOpen">
                    <div class="relative w-full max-w-6xl mx-auto flex">
                        <div class="flex-1 pr-6">
                            <ul class="space-y-8" ref="subNavItems">
                                <li v-for="(subitem, index) in activeSubnav" :key="index">
                                    <a
                                        :href="subitem.url"
                                        class="block font-serif font-bold text-20 lg:text-26 leading-tight normal-case text-white transition duration-500 hover:underline hover:text-gray-light hover:opacity-75"
                                        :class="{ 'underline text-gray-light opacity-75': subitem.active }"
                                        >{{ subitem.label }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="flex-1">
                            <h2 class="-mt-1 font-serif font-semibold text-36 lg:text-56 text-gray-darker leading-none" ref="subLabel">{{ activeLabel }}</h2>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { gsap, Cubic } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    props: {
        siteUrl: null,
        navItems: null
    },

    data() {
        return {
            activeIndex: -1,
            subnavOpen: false,
            mobileNavOpen: false,
            activeMobileIndex: -1,
            scrollingUp: false,
            newScrollTop: 0,
            currentScrollTop: 0
        };
    },

    computed: {
        activeLabel() {
            return this.activeIndex > -1 ? this.navItems[this.activeIndex].label : null;
        },
        activeSubnav() {
            return this.activeIndex > -1 ? this.navItems[this.activeIndex].subitems : null;
        }
    },

    methods: {
        openSubnav(navIndex) {
            this.activeIndex = navIndex;
            this.subnavOpen = true;
        },

        closeSubnav(navIndex) {
            this.subnavOpen = false;
            this.activeIndex = -1;
        },
        toggleMobileSubnav(subnavIndex) {
            this.activeMobileIndex = this.activeMobileIndex !== subnavIndex ? subnavIndex : -1;
        },
        openMobileNav() {
            this.mobileNavOpen = true;
        },

        closeMobileNav() {
            this.mobileNavOpen = false;
        },
        onPageScroll() {
            var windowHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            var headerHeight = 80;

            this.currentScrollTop = windowHeight;

            if (this.newScrollTop < this.currentScrollTop && windowHeight > headerHeight + headerHeight) {
                this.scrollingUp = true;
            } else if (this.newScrollTop > this.currentScrollTop && !(windowHeight <= headerHeight)) {
                this.scrollingUp = false;
            }
            this.newScrollTop = this.currentScrollTop;
        },
        beforeEnter: function(el) {
            gsap.set([this.$refs.subNavItems.querySelectorAll('li'), this.$refs.subLabel], { x: '-=30', opacity: 0 });

            gsap.set(this.$refs.closeButton, { scale: 2.0, opacity: 0 });
        },
        enter: function(el, done) {
            gsap.to([this.$refs.subLabel, this.$refs.subNavItems.querySelectorAll('li')], 0.6, { ease: Cubic.easeOut, x: '0', opacity: 1, stagger: 0.05, delay: 0.4, onComplete: done });

            gsap.to(this.$refs.closeButton, 0.6, { ease: Cubic.easeOut, scale: 1.0, opacity: 1, delay: 0.4 });
        },
        leave: function(el, done) {
            gsap.to([this.$refs.subLabel, this.$refs.subNavItems.querySelectorAll('li')], 0.6, { ease: Cubic.easeInOut, x: '-=30', opacity: 0, stagger: 0.05, delay: 0.0, onComplete: done });

            gsap.to(this.$refs.closeButton, 0.6, { ease: Cubic.easeInOut, scale: 0.0, opacity: 0, delay: 0.0 });
        },
        mobileBeforeEnter: function(el) {
            gsap.set([this.$refs.mobileNavItems.querySelectorAll('li'), this.$refs.mobileFooter], { x: '-=30', opacity: 0 });

            gsap.set(this.$refs.mobileCloseButton, { scale: 2.0, opacity: 0 });
        },
        mobileEnter: function(el, done) {
            gsap.to([this.$refs.mobileNavItems.querySelectorAll('li'), this.$refs.mobileFooter], 0.6, { ease: Cubic.easeOut, x: '0', opacity: 1, stagger: 0.05, delay: 0.4, onComplete: done });

            gsap.to(this.$refs.mobileCloseButton, 0.6, { ease: Cubic.easeOut, scale: 1.0, opacity: 1, delay: 0.4 });
        },
        mobileLeave: function(el, done) {
            gsap.to([this.$refs.mobileFooter, this.$refs.mobileNavItems.querySelectorAll('li')], 0.6, { ease: Cubic.easeInOut, x: '-=30', opacity: 0, stagger: 0.05, delay: 0.0, onComplete: done });

            gsap.to(this.$refs.mobileCloseButton, 0.6, { ease: Cubic.easeInOut, scale: 0.0, opacity: 0, delay: 0.0 });
        }
    },

    created() {},

    mounted() {
        // ScrollTrigger.create({
        //     trigger: 'window',
        //     onUpdate: self => console.log('direction:', self.direction)
        // });

        // We will want to throttle this at some point
        window.addEventListener('scroll', this.onPageScroll);
    }
};
</script>
