import Vue from 'vue';
import styles from './styles/app.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// This was found here and I don't fully understand why we need to manually register the plugin:
// https://greensock.com/forums/topic/24305-scroll-trigger-react/
// if (typeof window !== `undefined`) {
//     gsap.registerPlugin(ScrollTrigger);
//     gsap.core.globals('ScrollTrigger', ScrollTrigger);
// }

Vue.config.productionTip = false;

import HeaderNav from './components/HeaderNav.vue';

const components = {
    HeaderNav,
    VideoPlayer: () => import(/* webpackChunkName: "videoplayer" */ './components/VideoPlayer'),
    BackgroundVideo: () => import(/* webpackChunkName: "backgroundvideo" */ './components/BackgroundVideo'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ './components/Carousel'),
    FormEmbed: () => import(/* webpackChunkName: "formembed" */ './components/FormEmbed')
};

new Vue({
    el: '#app',
    components,

    mounted() {
        AOS.init({
            once: true,
            duration: 750,
            delay: 250,
            easing: 'ease-out-quart'
        });
    },

    created() { }
});
